import React from 'react';
import Lottie from 'react-lottie';
import lottieScissor from '../images/lottieScissor.json'; // Assurez-vous que le chemin vers votre fichier JSON est correct

const LottieScissor = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieScissor,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div>
      <Lottie options={defaultOptions} height={50} width={50} />
    </div>
  );
};

export default LottieScissor;
