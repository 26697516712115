import React from 'react';
import { Container, Typography, List, ListItem, ListItemText, Box, Paper, Link } from '@mui/material';
import Header from './Header';
import '../styles/Header.css';

const PolitiqueConf = () => {
  return (
    <div style={{ backgroundColor: 'black', minHeight: '100vh', color: 'white' }}>
    <Header />
    <Container maxWidth="md" style={{ marginTop: "100px" }}>
        
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px',backgroundColor:"transparent",borderColor:"#D7B7FF",borderWidth:2,borderStyle:"solid" }}>
        <Typography style={{color:"white"}} variant="h4" gutterBottom>
          Politique de Confidentialité
        </Typography>
        
        <Typography style={{color:"white"}} variant="h5" gutterBottom>
          Introduction
        </Typography>
        <Typography style={{color:"white"}} paragraph>
          UBHAIR  respecte la vie privée de ses utilisateurs . Cette politique de confidentialité explique comment nous recueillons, utilisons, divulguons et protégeons vos informations lorsque vous utilisez notre application mobile UBHAIR. Veuillez lire attentivement cette politique de confidentialité. Si vous n'acceptez pas les termes de cette politique, veuillez ne pas accéder à l'application.
        </Typography>
        
        <Typography style={{color:"white"}} variant="h5" gutterBottom>
          Collecte des Informations
        </Typography>
        <Typography style={{color:"white"}} paragraph>
          Nous pouvons recueillir des informations sur vous de diverses manières. Les informations que nous pouvons recueillir via l'application comprennent :
        </Typography>
        
        <Typography style={{color:"white"}} variant="h6" gutterBottom>
          Informations Personnelles
        </Typography>
        <List>
          <ListItem>
            <ListItemText style={{color:"white"}} primary="Clients : Nom, adresse e-mail, numéro de téléphone, adresse postale." />
          </ListItem>
          <ListItem>
            <ListItemText style={{color:"white"}} primary="Coiffeurs : Nom, adresse e-mail, numéro de téléphone, adresse postale, diplôme de coiffure, autres documents de vérification." />
          </ListItem>
        </List>
        
        <Typography style={{color:"white"}} variant="h6" gutterBottom>
          Informations Automatiques
        </Typography>
        <List>
          <ListItem>
            <ListItemText style={{color:"white"}} primary="Données de géolocalisation." />
          </ListItem>
          <ListItem>
            <ListItemText style={{color:"white"}} primary="Données d'utilisation (fréquence d'utilisation, pages visitées)." />
          </ListItem>
          <ListItem>
            <ListItemText style={{color:"white"}} primary="Informations sur l'appareil (modèle, système d'exploitation, identifiants uniques)." />
          </ListItem>
        </List>
        
        <Typography style={{color:"white"}} variant="h5" gutterBottom>
          Utilisation des Informations
        </Typography>
        <Typography style={{color:"white"}} paragraph>
          Nous utilisons les informations recueillies de la manière suivante :
        </Typography>
        
        <Typography style={{color:"white"}} variant="h6" gutterBottom>
          Fournir et Gérer nos Services
        </Typography>
        <List>
          <ListItem>
            <ListItemText style={{color:"white"}} primary="Créer et gérer votre compte utilisateur." />
          </ListItem>
          <ListItem>
            <ListItemText style={{color:"white"}} primary="Permettre aux coiffeurs de publier des annonces." />
          </ListItem>
          <ListItem>
            <ListItemText style={{color:"white"}} primary="Faciliter la prise de rendez-vous entre clients et coiffeurs." />
          </ListItem>
          <ListItem>
            <ListItemText style={{color:"white"}} primary="Envoyer des notifications de réservation et de confirmation." />
          </ListItem>
        </List>
        
        <Typography style={{color:"white"}} variant="h6" gutterBottom>
          Amélioration de l'Expérience Utilisateur
        </Typography>
        <List>
          <ListItem>
            <ListItemText style={{color:"white"}} primary="Analyser les tendances d'utilisation pour améliorer l'application." />
          </ListItem>
          <ListItem>
            <ListItemText style={{color:"white"}} primary="Développer de nouvelles fonctionnalités et services." />
          </ListItem>
        </List>
        
        <Typography style={{color:"white"}} variant="h6" gutterBottom>
          Sécurité et Conformité
        </Typography>
        <List>
          <ListItem>
            <ListItemText style={{color:"white"}} primary="Vérifier l'identité des coiffeurs professionnels." />
          </ListItem>
          <ListItem>
            <ListItemText style={{color:"white"}} primary="Prévenir les fraudes et les abus." />
          </ListItem>
          <ListItem>
            <ListItemText style={{color:"white"}} primary="Respecter les obligations légales." />
          </ListItem>
        </List>
        
        <Typography style={{color:"white"}} variant="h5" gutterBottom>
          Partage des Informations
        </Typography>
        <Typography style={{color:"white"}} paragraph>
          Nous pouvons partager vos informations dans les circonstances suivantes :
        </Typography>
        
        <Typography style={{color:"white"}} variant="h6" gutterBottom>
          Avec votre Consentement
        </Typography>
        <Typography style={{color:"white"}} paragraph>
          Lorsque vous acceptez de partager vos informations avec des tiers spécifiques.
        </Typography>
        
        <Typography style={{color:"white"}} variant="h6" gutterBottom>
          Avec les Prestataires de Services
        </Typography>
        <Typography style={{color:"white"}} paragraph>
          Avec les prestataires de services qui nous aident à exploiter notre application, tels que les services de paiement.
        </Typography>
        
        <Typography style={{color:"white"}} variant="h6" gutterBottom>
          Obligations Légales
        </Typography>
        <Typography style={{color:"white"}} paragraph>
          Lorsque nous devons divulguer vos informations pour nous conformer à des obligations légales ou pour protéger nos droits.
        </Typography>
        
        <Typography style={{color:"white"}} variant="h5" gutterBottom>
          Protection de Vos Informations
        </Typography>
        <Typography style={{color:"white"}} paragraph>
          Nous utilisons des mesures de sécurité administratives, techniques et physiques pour protéger vos informations personnelles. Cependant, aucune méthode de transmission sur Internet ou méthode de stockage électronique n'est sécurisée à 100%, et nous ne pouvons pas garantir une sécurité absolue.
        </Typography>
        
        <Typography style={{color:"white"}} variant="h5" gutterBottom>
          Vos Droits
        </Typography>
        <Typography style={{color:"white"}} paragraph>
          Vous avez le droit d'accéder, de corriger ou de supprimer vos informations personnelles. Vous pouvez également vous opposer au traitement de vos données personnelles ou demander leur limitation. Pour exercer ces droits, veuillez nous contacter à l'adresse indiquée ci-dessous.
        </Typography>
        
        <Typography style={{color:"white"}} variant="h5" gutterBottom>
          Modifications de cette Politique de Confidentialité
        </Typography>
        <Typography style={{color:"white"}} paragraph>
          Nous pouvons mettre à jour cette politique de confidentialité de temps en temps. Nous vous informerons de tout changement en publiant la nouvelle politique sur l'application. Vous êtes encouragé à consulter cette politique régulièrement pour rester informé de nos pratiques.
        </Typography>
        
        <Typography style={{color:"white"}} variant="h5" gutterBottom>
          Nous Contacter
        </Typography>
        <Typography style={{color:"white"}} paragraph>
          Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité, veuillez nous contacter à :
        </Typography>
        <Box mb={2}>
          <Typography style={{color:"white"}} paragraph>
            UBHAIR<br />
           
            Email : <Link href="mailto:[uber-info@gmail.com]">uber-info@gmail.com</Link><br />
            
          </Typography>
        </Box>
        
        <Typography style={{color:"white"}} variant="h5" gutterBottom>
          Conclusion
        </Typography>
        <Typography style={{color:"white"}} paragraph>
          En utilisant l'application UBHAIR, vous acceptez les termes de cette politique de confidentialité. Nous nous engageons à protéger vos informations et à vous fournir un service sûr et sécurisé.
        </Typography>
      </Paper>
    </Container>
    </div>
  );
};

export default PolitiqueConf;
