import React from 'react';
import { Link } from "react-router-dom";
import Header from './Header';
import { IoTicketOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
import { FaCheckDouble } from "react-icons/fa6";
import { RiTwitterXLine } from "react-icons/ri";
import { FaInstagram } from "react-icons/fa";
import { CiLinkedin } from "react-icons/ci";
import LottieScissor from '../Lotties/LottieScissor';
import LottieCustomer from '../Lotties/LottieCustomer';

import '../styles/Accueil.css';

const iphonePres1 = require('../images/ubhairPhonePres1Finale.png');
const iphonePres2 = require('../images/ubhairIphonePres2Finale.png');

const logoAppleStore = require('../images/DownloadApple2.png');
const logoGooglePlay = require('../images/DownloadGoogle2.png');


function Accueil() {
    return (
        <div style={{ backgroundColor: 'black', minHeight: '100vh', color: 'white' }}>
            
            <Header />

            <section>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'row', marginTop: "100px", }}>
                <div style={{ marginLeft: "80px",  }}>
                    <h1 style={{ margin: "0px", fontSize: 50 }}>UBHAIR, votre coiffure</h1>
                    <h1 style={{ margin: "0px", fontSize: 50 }}>de rêve à portée</h1>
                    <h1 style={{ margin: "0px", fontSize: 50 }}>de main !</h1>

                    <h1 style={{ fontSize: 15, marginTop: "30px" }}>Réservez votre coiffure avec UBHAIR et</h1>
                    <h1 style={{ fontSize: 15, marginTop: "-5px" }}>vivez une expérience sans tracas !</h1>
                </div>

                <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                    <img src={iphonePres1} alt='iphonePres1' className="bounce" style={{ width: "150%", height: "150%",  }} />
                </div>
            </div>
            </section>



            <section style={{ marginTop: "100px" }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'row', marginTop: "100px", }}>
                

                <div style={{ flex: 1, display: 'flex', justifyContent: 'center',width: "100%",height:"100%" }}>
                    <img src={iphonePres2} alt='iphonePres2' style={{ width: "210%", height: "210%", marginTop: "20px" }} />
                </div>

                <div style={{ marginLeft: "80px",marginTop:"100px",padding:"40px" }}>
                    <h1 style={{ margin: "0px", fontSize: 50 }}>Réserve ton </h1>
                    <h1 style={{ margin: "0px", fontSize: 50 }}>créneau et fais-toi</h1>
                    <h1 style={{ margin: "0px", fontSize: 50 }}>couper les cheveux</h1>

                    <h1 style={{ fontSize: 15, marginTop: "30px" }}>Les différentes étapes :</h1>


                    <div style={{marginTop:"100px",height:"120px",width:"80%",backgroundColor:"#D7B7FF",borderRadius:15,flexDirection:"row",display:"flex"}}>

                 
<IoTicketOutline style={{marginTop:"20px",marginLeft:"30px",color:"gray",fontSize:30}} />


<div>

<h1 style={{fontSize:17,textAlign:"center",color:"black",marginTop:"20px"}}>
    Réservation d'un créneau
</h1>

<h1 style={{fontSize:17,textAlign:"center",marginLeft:"30px",color:"black",fontWeight:"normal"}}>
    Réserve ton créneau à l'heure
</h1>

<h1 style={{fontSize:17,textAlign:"center",marginRight:"60px",marginTop:"-15px",color:"black",fontWeight:"normal"}}>
    que tu souhaites !
</h1>


</div>

</div>






<div style={{marginTop:"20px",height:"50px",width:"80%",backgroundColor:"transparent",borderRadius:15,display:"flex"}}>


<div style={{flexDirection:"row",display:"flex"}}>

<FaCheck style={{marginTop:"10px",marginLeft:"30px",color:"gray",fontSize:30}} />


<h1 style={{fontSize:17,marginLeft:"30px"}}>Réservation confirmée</h1>


<h1>

</h1>

</div>





</div>




<div style={{marginTop:"20px",height:"50px",width:"80%",backgroundColor:"transparent",borderRadius:15,display:"flex"}}>


<div style={{flexDirection:"row",display:"flex"}}>

<FaCheckDouble style={{marginTop:"10px",marginLeft:"30px",color:"gray",fontSize:30}} />


<h1 style={{fontSize:17,marginLeft:"30px"}}>Prestation réalisée</h1>


<h1>

</h1>

</div>





</div>





                </div>


            </div>
            </section>







            <section>
      <div style={{ marginTop: "100px" }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <h1 style={{fontSize:45}}>2 choix s'offrent à vous</h1>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: "100px" }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: "200px", width: "270px", backgroundColor: "transparent", borderRadius: 10 }}>

            <div style={{marginTop:"-60px"}}>
            <LottieScissor /> 

          




            <div class="card">
  <div class="card-content">
    <p class="card-title">Coiffeur
    </p><p class="card-para">Sur l'application mobile UBHAIR, les coiffeurs publient leur annonce , acceptent des réservations, réalisent des coupes personnalisées à domicile
                 et confirment la prestation terminée, offrant une expérience de beauté pratique et de qualité..</p>
  </div>
</div>


{/*
            <div>
                <h1 style={{flexWrap:"wrap",fontSize:13,textAlign:"justify",marginLeft:"10px",marginRight:"10px",lineHeight: '1.4'}}>
                Sur l'application mobile UBHAIR, les coiffeurs publient leur annonce , acceptent des réservations, réalisent des coupes personnalisées à domicile
                 et confirment la prestation terminée, offrant une expérience de beauté pratique et de qualité.
                </h1>
            </div>

    */}


            </div>

            



          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: "200px", width: "270px", backgroundColor: "transparent", borderRadius: 10 }}>
            

          <div style={{marginTop:"-60px"}}>
            <LottieCustomer />

       


{/*
            <div>
                <h1 style={{flexWrap:"wrap",fontSize:13,textAlign:"justify",marginLeft:"10px",marginRight:"10px",lineHeight: '1.4'}}>
                Sur l'application mobile UBHAIR, les clients peuvent facilement réserver des créneaux, choisir des coiffeurs
                 professionnels à domicile et confirmer la qualité de la prestation,
                 profitant d'une expérience de beauté pratique et personnalisée.
                </h1>
            </div>

    */}

            <div class="card">
  <div class="card-content">
    <p class="card-title">Client
    </p><p class="card-para">Sur l'application mobile UBHAIR, les clients peuvent facilement réserver des créneaux, choisir des coiffeurs
                 professionnels à domicile et confirmer la qualité de la prestation,
                 profitant d'une expérience de beauté pratique et personnalisée.</p>
  </div>
</div>



            </div>



          </div>
        </div>
      </div>
    </section>






<section style={{marginTop:"300px",backgroundColor:"darkgray",borderTopRightRadius:40,borderTopLeftRadius:40}}>


        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>


        <div style={{flexDirection:"column"}}>
        <h1 style={{ color: "#D7B7FF", fontWeight: "bold", textDecoration: "none", fontSize: 30,textAlign:"center" }}>UBHAIR</h1>

        <h1 style={{textAlign:"center"}}>Le révolution de la coiffure</h1>


        <h1 style={{marginTop:"50px",textAlign:"center"}}>Bientôt disponible sur </h1>


        <div style={{justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"row",marginTop:"20px"}}>

            <img src={logoAppleStore} alt='logoAppleStore' style={{width:"150px",height:"50px",}} />
            <img src={logoGooglePlay} alt='logoGooglePlay' style={{width:"160px",height:"70px",marginLeft:"20px"}} />
        </div>


        <div style={{justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"row",marginTop:"20px"}}>

    

            <Link style={{fontSize:16,marginLeft:"20px",color:"lightgray"}} to="/confidentialite"> 
            Confidentialité
        </Link>

            <h1 style={{fontSize:16,marginLeft:"50px",color:"lightgray"}}>ubhair-info@gmail.com</h1>


        </div>


        <div style={{justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"row",marginTop:"20px"}}>


            <div style={{width:"30px",height:"30px",backgroundColor:"black",borderRadius:20,justifyContent:"center",alignItems:"center",display:"flex"}}>
            <RiTwitterXLine style={{color:"white",fontSize:15}} />
            </div>


            <div style={{marginLeft:"20px",width:"30px",height:"30px",backgroundColor:"black",borderRadius:20,justifyContent:"center",alignItems:"center",display:"flex"}}>
            <FaInstagram  style={{color:"white",fontSize:15}} />
            </div>


            <div style={{marginLeft:"20px",width:"30px",height:"30px",backgroundColor:"black",borderRadius:20,justifyContent:"center",alignItems:"center",display:"flex"}}>
            <CiLinkedin style={{color:"white",fontSize:15}} />
            </div>

            
        </div>

        <div style={{marginTop:"30px",textAlign:"center"}}> 
            <h1 style={{fontSize:12,color:"lightgray",marginLeft:10}}>© 2024, UBHAIR LLC.</h1>
            </div>



        </div>

        </div>

</section>



         





        </div>
    );
}

export default Accueil;
