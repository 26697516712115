import React from "react";
import { Link } from "react-router-dom";
import "../styles/Header.css";

function Header() {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 100px", }}>
      <div style={{marginTop: "30px"}}>
        <Link style={{ color: "#D7B7FF", fontWeight: "bold", textDecoration: "none", fontSize: 30, }} to="/">
          UBHAIR
        </Link>
      </div>
      <div style={{marginTop: "30px"}}>
      <Link className="button" to="/contact"> {/* Appliquez la classe "button" */}
          Contact
        </Link>
      </div>
    </div>
  );
}


export default Header;
