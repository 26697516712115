import React, { useState } from 'react';
import Header from './Header';
import '../styles/Contact.css';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { FaUser } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import LottieContact from '../Lotties/LottieContact';


function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if(formData.name === '' || formData.email === '' || formData.message === ''){
            toast.error('Veuillez remplir tous les champs');
            return;
        }

        // Mappage des champs pour correspondre aux variables du modèle d'email
        const templateParams = {
            from_name: formData.name,
            to_name: 'UbHair Info',  // Remplacez par le nom du destinataire si nécessaire
            message: formData.message,
            reply_to: formData.email
        };

        emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            templateParams,
            process.env.REACT_APP_EMAILJS_USER_ID,
            
            
        )
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            toast.success('Email envoyé avec succès!');
        }, (err) => {
            console.log('FAILED...', err);
            toast.error('Échec de l\'envoi de l\'email. Veuillez réessayer.');
        });
    };

    return (
        <div style={{ backgroundColor: 'black', minHeight: '100vh', color: 'white' }}>
            <Header />
            <ToastContainer 
                position="top-right" 
                autoClose={5000} 
                hideProgressBar={false} 
                newestOnTop={false} 
                closeOnClick 
                rtl={false} 
                pauseOnFocusLoss 
                draggable 
                pauseOnHover
            />
            <section style={{ marginTop: "30px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "200px", flexDirection: "row" }}>
                    <div style={{ flexDirection: "column", display: "flex" }}>
                        <h1 style={{ marginLeft: "0px", fontSize: 55 }}>Contactez-nous</h1>

                        <form onSubmit={handleSubmit}>
                            <div style={{ position: "relative", marginTop: "30px" }}>
                                <FaUser style={{ position: "absolute", top: "50%", left: "10px", transform: "translateY(-50%)", color: "gray", fontSize: 20 }} />
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Nom"
                                    value={formData.name}
                                    onChange={handleChange}
                                    style={{ paddingLeft: "40px", borderRadius: 15, width: "400px", height: "45px", color: "gray", backgroundColor: "lightgray", border: "none" }}
                                />
                            </div>

                            <div style={{ position: "relative", marginTop: "30px" }}>
                                <IoMdMail style={{ position: "absolute", top: "50%", left: "10px", transform: "translateY(-50%)", color: "gray", fontSize: 20 }} />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    style={{ paddingLeft: "40px", borderRadius: 15, width: "400px", height: "45px", color: "gray", backgroundColor: "lightgray", border: "none" }}
                                />
                            </div>

                            <div style={{ position: "relative", marginTop: "30px" }}>
                               
                                <textarea
                                    name="message"
                                    placeholder="Message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    style={{ fontSize:13,paddingTop:"10px",paddingLeft: "10px", borderRadius: 15, width: "430px", height: "95px", color: "gray", backgroundColor: "lightgray", border: "none" }}
                                />
                            </div>

                            <button type="submit" style={{ marginTop: "40px",width:"435px" }} className="button2">Envoyer</button>
                        </form>
                    </div>
                    <div style={{ marginRight: "100px", marginTop: "80px" }}>
                        <LottieContact />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Contact;
