import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Accueil from './navigation/Accueil';
import Contact from './navigation/Contact';
import PolitiqueConf from './navigation/PolitiqueConf';







const App = () => (
  <Router>
    <div>
      <Routes>
        <Route path="/" element={<Accueil />} exact />
        <Route path="/contact" element={<Contact />}  />
        <Route path="/confidentialite" element={<PolitiqueConf />}  />
        
       


     
      </Routes>
    </div>
  </Router>
);

export default App;